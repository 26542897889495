import { createHashRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import MainLayout from './pages/Main/MainLayout';
import { lazy, Suspense, useContext, useEffect } from 'react';
import AuthLayout from './pages/Main/Auth/AuthLayout';
import Loading from './components/Loading/Loading';
import LoginProtector from './Protectors/LoginProtector';
import ExamLayout from './pages/Exam/ExamLayout';
import { RoleContext } from './Context/RoleContext';
import axios from 'axios';
import { BaseUrl } from './helpers/Api';
import { useCookies } from 'react-cookie';
import SuperAdmin from './Protectors/SuperAdmin';
import Admin from './Protectors/AdminProtector';
import ExamProtector from './Protectors/ExamProtecor';
import NotFound from './pages/Main/NotFound/NotFound';
import Success from './pages/Main/Auth/Success/Success'
import AdminReport from './pages/Main/Report/AdminReport'
import Report from './pages/Main/Report/Report'
import HrReport from './pages/Main/Report/HrReport'

//////////////////////// Main Layoyt /////////////////////////////////////////////////////
const Contacts = lazy(() => import('./pages/Main/home/Home'));
const About = lazy(() => import('./pages/Main/About/About'));

const AllExams = lazy(() => import("./pages/Main/AllExams/AllExams"));
const CreateExam = lazy(() => import('./pages/Main/CreateExam/CreateExam'));
const UpdateAssestment = lazy(() => import("./pages/Main/Update/Assestment/Assestment"));
const PreviewAssestment = lazy(() => import("./pages/Main/Preview/Assestment/Assestment"));

const AllTests = lazy(() => import('./pages/Main/Test/Test'));
const UpdateTest = lazy(() => import("./pages/Main/Update/Test/Test"));
const PreviewTest = lazy(() => import("./pages/Main/Preview/Test/Test"));

const AllAdmins = lazy(() => import('./pages/Main/Admin/All/AllAdmins'));
const CreateAdmin = lazy(() => import('./pages/Main/Admin/CreateAdmin/CreateAdmin'));

const AllHRs = lazy(() => import('./pages/Main/AllHR/AllHr'));

const CreateCandinates = lazy(() => import('./pages/Main/Candidates/CreateCandidates/CreateCandidates'));
const AllCandidates = lazy(() => import('./pages/Main/Candidates/AllCandidates/AllCandidates'));

const CandidateResults = lazy(() => import('./pages/Main/Results/CandidateResults/ResultDetails'));

//////////////////////// Exam Layout /////////////////////////////////////////////////////
const Exam = lazy(() => import("./pages/Exam/Exam/Exam"));

// Profile Routes
const Profile = lazy(() => import('./pages/Main/Profile/Profile'));
const Settings = lazy(() => import('./pages/Main/Settings/Settings'));

//////////////////////// Auth Layoyt /////////////////////////////////////////////////////
const Login = lazy(() => import("./pages/Main/Auth/Login/Login"));
const Contact = lazy(() => import("./pages/Main/Auth/Contact/Contact"));
const Forget = lazy(() => import("./pages/Main/Auth/Forget/Forget"));


function App() {


  const { setRole } = useContext(RoleContext);

  const [token] = useCookies(['ElBayan'])

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!token.ElBayan) return;

      try {
        const { data } = await axios.post(`${BaseUrl}/v1/checkRole/status`, {
          token: token.ElBayan,

        });
        console.log(data?.role);

        setRole(data?.role)
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [token.ElBayan]); // Add token.ElBayan as a dependency


  const routes = createHashRouter([
    {
      path: "", element: <MainLayout />, children: [
        {
          index: true, element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <AllExams />
            </LoginProtector>
          </Suspense>
        },
        {
          path: "About", element: <Suspense fallback={<Loading />}><About /></Suspense>
        }, {
          path: "Contacts", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Admin>
                <Contacts />
              </Admin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "CreateAssessment", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <CreateExam Test={false} />
            </LoginProtector>
          </Suspense>
        }, {
          path: "Tests", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <AllTests />
            </LoginProtector>
          </Suspense>
        }, {
          path: "TestCreation", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <CreateExam Test={true} />
            </LoginProtector>
          </Suspense>
        }, {
          path: "UpdateAssestment/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <UpdateAssestment />
            </LoginProtector>
          </Suspense>
        }, {
          path: "UpdateTest/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <UpdateTest />
            </LoginProtector>
          </Suspense>
        }, {
          path: "PreviewAssestment/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <PreviewAssestment />
            </LoginProtector>
          </Suspense>
        }, {
          path: "PreviewTest/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <PreviewTest />
            </LoginProtector>
          </Suspense>
        }, {
          path: "AllAdmins", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <SuperAdmin>
                <AllAdmins />
              </SuperAdmin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "CreateAdmin", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <SuperAdmin>
                <CreateAdmin />
              </SuperAdmin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "AllHRs", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Admin>
                <AllHRs />
              </Admin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "CandinateCreation", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <CreateCandinates />
            </LoginProtector>
          </Suspense>
        }, {
          path: "AllCandinates", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <AllCandidates />
            </LoginProtector>
          </Suspense>
        }, {
          path: "Report/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Report />
            </LoginProtector>
          </Suspense>
        }, {
          path: "AdminReport/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <SuperAdmin>
                <AdminReport />
              </SuperAdmin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "HrReport/:id", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Admin>
                <HrReport />
              </Admin>
            </LoginProtector>
          </Suspense>
        }, {
          path: "ResultDetails/Assestment/:AssestmentId/Candidate/:CandidateId", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <CandidateResults />
            </LoginProtector>
          </Suspense>
        }, {
          path: "Profile", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Profile />
            </LoginProtector>
          </Suspense>
        }, {
          path: "Settings", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Settings />
            </LoginProtector>
          </Suspense>
        }
      ]
    }, {
      path: "Auth", element: <AuthLayout />, children: [
        {
          index: true, element: <Suspense fallback={<Loading />}><Login /></Suspense>
        },
        {
          path: "Contact", element: <Suspense fallback={<Loading />}><Contact /></Suspense>
        },
        {
          path: "Success", element: <Suspense fallback={<Loading />}>
            <Success />
          </Suspense>
        },
        {
          path: "Forget", element: <Suspense fallback={<Loading />}>
            <Forget />
          </Suspense>
        }
      ]
    }, {
      path: "", element: <ExamLayout />, children: [
        {
          path: "Exam/:token", element: <Suspense fallback={<Loading />}>
            <ExamProtector>
              <Exam />
            </ExamProtector>
          </Suspense>
        },
        {
          path: "Exam/Success", element: <Suspense fallback={<Loading />}>
            <Success Exam={true} />
          </Suspense>
        },
        {
          path: "Exam/Submitted", element: <Suspense fallback={<Loading />}>
            <Success Already={true} />
          </Suspense>
        }
      ]
    }, {
      path: "*", element: <NotFound />
    }
  ]);


  return <RouterProvider router={routes}></RouterProvider>
}

export default App;
