import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom'

export default function LoginProtector(props) {
    const [cookies] = useCookies(["ElBayan"]);
    const token = cookies.ElBayan;



    if (token) {
        return props.children
    } else {
        return <Navigate to={"/Auth"} />
    }
}
