import React, { useContext } from 'react';
import './Report.scss';
// import { useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../../../helpers/Api';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading/Loading';
import { useCookies } from 'react-cookie';
import { MdDelete } from "react-icons/md";
import { RoleContext } from '../../../Context/RoleContext';
import { toast } from 'react-toastify';
import { ToastOptions } from '../../../helpers/ToastOptions';

export default function Report() {

    const { id } = useParams();
    const [Cookies] = useCookies(['ElBayan']);

    const { Role } = useContext(RoleContext)

    // const formik = useFormik({
    //     initialValues: {
    //         feedback: ""
    //     }
    // })

    const GetCandidateDetails = async () => {
        const reponse = await axios.get(`${BaseUrl}/v1/candidate/${id}`, {
            headers: {
                authorization: Cookies?.ElBayan && `Bearer ${Cookies?.ElBayan}`
            }
        });

        return reponse.data
    }


    const { data, isLoading, refetch } = useQuery("Get Candidate Details", GetCandidateDetails, {
        cacheTime: 0
    });


    const DeleteResult = async (AssessmentId) => {
        await axios.delete(`${BaseUrl}/v1/assessment-result/assessmentId/${AssessmentId}/candidateId/${id}`, {
            headers: {
                authorization: Cookies?.ElBayan && `Bearer ${Cookies?.ElBayan}`
            }
        }).then((res) => {
            console.log(res);

            if (res.data.status) {
                refetch().then(() => toast.success("Result deleted successfully", ToastOptions("success")));
            }
        }).catch((err) => console.log(err))
    }

    if (isLoading) return <Loading />

    return <section className='Report p-6'>
        <h1 className='text-xl'>Candidate Report</h1>

        <div className="w-full my-5 Information">
            <h2>Information</h2>
            <div className="Information_Details  mt-3">
                <div className="w-full flex justify-between items-center">
                    <h3>Name</h3>
                    <p>{data?.candidate?.name}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Email</h3>
                    <p>{data?.candidate?.email}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Phone</h3>
                    <p>{data?.candidate?.phone}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Total Completed Assestments</h3>
                    <p>{data?.assessmentResults?.length}</p>
                </div>
            </div>

        </div>


        {data?.assessmentResults?.length === 0 && <div className="p-4 mb-4 mt-2 text-sm text-black rounded-lg bg-[#F5F5F5]" role="alert">
            <span className="font-medium">For noticed</span> The candidate has not taken exams.
        </div>
        }


        {data?.assessmentResults?.map((result, index) => <div key={index}>

            <h1 className='my-1'>{result?.assessmentTitle}</h1>
            <p>{result?.assessmentDescription !== "description not found" ? result?.assessmentDescription : null}</p>
            <div className="ReportCards w-full flex lg:flex-nowrap flex-wrap items-center gap-3 my-5">
                <div className="ReportCard">
                    <h2>Candidate Score</h2>
                    <p>{result?.score}</p>
                </div>
                <div className="ReportCard">
                    <h2>Assestment Score</h2>
                    <p>{result?.totalScore}</p>
                </div>
                <div className="ReportCard">
                    <h2>Total Precentage</h2>
                    <p>{parseInt(result?.percentage)} %</p>
                </div>
                <div className="ReportCard">
                    <h2>Candidate stage</h2>
                    <p>{result?.status}</p>
                </div>
            </div>

            {((result?.overallFeedback !== "assessment not found" && Role !== "admin" && Role !== "super-admin") && <div id="alert-additional-content-5" className="p-4 border border-gray-300 rounded-lg bg-primary-50" role="alert">
                <div className="flex items-center">
                    <svg className="flex-shrink-0 w-4 h-4 me-2 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <h3 className="text-lg font-medium text-gray-800 ">Admin Feedback</h3>
                </div>
                <div className="mt-2 mb-4 text-sm text-gray-800 ">
                    {result?.overallFeedback}
                </div>
                <div className="flex">
                    <Link type="button" to={`/ResultDetails/Assestment/${result?.assessmentId}/Candidate/${id}`} className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 
                    focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center">
                        <svg className="me-2 h-3 w-3 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                            <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                        </svg>
                        View Result
                    </Link>
                </div>
            </div>)}

            {((Role === "admin" || Role === "super-admin") && <div id="alert-additional-content-5" className="p-4 border border-gray-300 rounded-lg bg-primary-50" role="alert">
                <div className="flex items-center">
                    <svg className="flex-shrink-0 w-4 h-4 me-2 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <h3 className="text-lg font-medium text-gray-800 ">Admin Feedback</h3>
                </div>
                <div className="mt-2 mb-4 text-sm text-gray-800 ">
                    {result?.overallFeedback}
                </div>
                <div className="flex">
                    <Link type="button" to={`/ResultDetails/Assestment/${result?.assessmentId}/Candidate/${id}`} className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 
                    focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center">
                        <svg className="me-2 h-3 w-3 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                            <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                        </svg>
                        View Result
                    </Link>
                    <button onClick={() => DeleteResult(result?.assessmentId)} type="button"
                        className='w-[120px] px-1 h-[30px] flex justify-center items-center border
                      border-red-800 text-red-800 rounded-lg gap-2'
                        data-dismiss-target="#alert-additional-content-5" aria-label="Close">

                        Delete <MdDelete className='text-red-800 w-4 h-4' />
                    </button>
                </div>
            </div>)}

            <hr className="h-px my-6 bg-gray-200 border-0" />

        </div>
        )}


        {/* <div className="FeedBack">
            <h4>Feedback</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <textarea id="message" rows="5" name='feedback' onChange={formik.handleChange} onBlur={formik.handleBlur}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border
                 border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Candidate Feedback"></textarea>
                </div>

                <div className="w-full flex justify-end">
                    <button type="submit" className="focus:outline-none text-white bg-primary-700 hover:bg-primary-800 focus:ring-4
                 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Send Feedback</button>
                </div>
            </form>
        </div> */}
    </section>

}
