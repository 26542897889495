import { createContext, useState } from "react";

export const RoleContext = createContext();

export default function RoleProvider({ children }) {
    const [Role, setRole] = useState();

    const [Assestment, setAssestment] = useState();

    const [TestLoading, setTestLoading] = useState();
    const [AssestmentLoading, setAssestmentLoading] = useState();


    return (
        <RoleContext.Provider value={{
            Role, setRole, Assestment, setAssestment, TestLoading,
            setTestLoading, AssestmentLoading, setAssestmentLoading
        }}>
            {children}
        </RoleContext.Provider>
    );
}
