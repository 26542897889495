import React from 'react'
import LevelImage from '../../../components/LevelImage'

export default function AdminAssestments({ Assestments, Assestment }) {


    if (Assestment) return <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Title
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Duration
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Level
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Total Candidates
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Total Completed
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Job Role
                    </th>
                </tr>
            </thead>
            <tbody>
                {Assestments?.map((Assestment, index) => <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {Assestment?.title}
                    </th>
                    <td className="px-6 py-4">
                        {Assestment?.duration}
                    </td>
                    <td className="px-6 py-4">
                        <LevelImage Level={Assestment?.candidateLevel} />
                    </td>
                    <td className="px-6 py-4">
                        {Assestment?.totalCandidates}
                    </td>
                    <td className="px-6 py-4">
                        {Assestment?.totalCompletedCandidates}
                    </td>
                    <td className="px-6 py-4">
                        {Assestment?.jobRole}
                    </td>
                </tr>)}

            </tbody>
        </table>
    </div>

    return <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        email
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Phone
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                {Assestments?.map((Candidate, index) => <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {Candidate?.name}
                    </th>
                    <td className="px-6 py-4">
                        {Candidate?.email}
                    </td>
                    <td className="px-6 py-4">
                        {Candidate?.phone}
                    </td>
                    <td className="px-6 py-4">
                        {Candidate?.status}
                    </td>
                </tr>)}

            </tbody>
        </table>
    </div>

}
