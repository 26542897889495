import React from 'react';
import './Report.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../../../helpers/Api';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading/Loading';
import { useCookies } from 'react-cookie';
import AdminAssestments from './AdminAssestments';

export default function HrReport() {

    const { id } = useParams();
    const [Cookies] = useCookies(['ElBayan']);



    const GetHrDetails = async () => {
        const respoonse = await axios.get(`${BaseUrl}/v1/hr/${id}`, {
            headers: {
                authorization: Cookies?.ElBayan && `Bearer ${Cookies?.ElBayan}`
            }
        });

        return respoonse.data?.hr
    }

    const { data, isLoading } = useQuery("Get Hr details", GetHrDetails, {
        cacheTime: 0
    });

    if (isLoading) return <Loading />

    return <section className='Report p-6'>
        <h1 className='text-xl'>Hr Report</h1>

        <div className="w-full my-5 Information">
            <h2>Information</h2>
            <div className="Information_Details  mt-3">
                <div className="w-full flex justify-between items-center">
                    <h3>Name</h3>
                    <p>{data?.name}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Email</h3>
                    <p>{data?.email}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Phone</h3>
                    <p>{data?.phone}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Company Name</h3>
                    <p>{data?.companyName}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Company Size</h3>
                    <p>{data?.companySize}</p>
                </div>
                <div className="w-full flex justify-between items-center">
                    <h3>Status</h3>
                    <p>{data?.isVerified ? "Verified" : "Not Verified"}</p>
                </div>
            </div>

        </div>

        <div className="ReportCards w-full flex flex-wrap items-center gap-3 my-5">
            <div className="ReportCard">
                <h2>Candidates Number</h2>
                <p>{data?.candidates?.length}</p>
            </div>
            <div className="ReportCard">
                <h2>Assessments Number</h2>
                <p>{data?.assessments?.length}</p>
            </div>
        </div>

        {data?.assessments?.length === 0 ? null : <div>
            <h1>All Assestments</h1>
            <div className="my-4">
                <AdminAssestments Assestments={data?.assessments} Assestment={true} />
            </div>
        </div>}

        {data?.candidates?.length === 0 ? null : <div className="mt-5">
            <h1>All Candidates</h1>
            <div className="my-4">
                <AdminAssestments Assestments={data?.candidates} Assestment={false} />
            </div>
        </div>}



    </section>

}
