export const ToastOptions = (Type) => {

    if (Type === "success") {

        return {
            className: "Toast",
            theme: "colored"
        }

    } else if (Type === "error") {
        return {
            theme: "colored",
            className: "ToastError"
        }
    }

}