import React from 'react'
import { Grid } from 'react-loader-spinner'

export default function ExamLoading({ Assestment, Test }) {


    if (Assestment) {
        return <div className="flex justify-center items-center w-full h-full px-24">
            <div className="w-screen h-screen md:w-1/2 flex justify-center items-center text-center">
                <div className="result-image-container flex flex-col justify-center items-center">
                    <Grid
                        visible={true}
                        height="80"
                        width="80"
                        color="#036666"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperStyle={{}}
                        wrapperClass="grid-wrapper"
                    />
                    <h1 className="text-3xl text-ptxt mt-5">Your Assestment is being Submitted</h1>
                    <p className="text-lg text-stxt">
                        We hope that you achive the best quality in your employees selection (if take many time because of vedio source size)
                    </p>
                </div>
            </div>
        </div>
    }

    if (Test) {
        return <div className="flex justify-center items-center w-full h-full px-24">
            <div className="w-screen h-screen md:w-1/2 flex justify-center items-center text-center">
                <div className="result-image-container flex flex-col justify-center items-center">
                    <Grid
                        visible={true}
                        height="80"
                        width="80"
                        color="#036666"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperStyle={{}}
                        wrapperClass="grid-wrapper"
                    />
                    <h1 className="text-3xl text-ptxt mt-5">Your Test is being Submitted</h1>
                    <p className="text-lg text-stxt">
                        We hope that you achive the best quality in your employees selection
                    </p>
                </div>
            </div>
        </div>
    }

    return <div className="flex justify-center items-center w-full h-full px-24">
        <div className="w-screen h-screen md:w-1/2 flex justify-center items-center text-center">
            <div className="result-image-container flex flex-col justify-center items-center">
                <Grid
                    visible={true}
                    height="80"
                    width="80"
                    color="#036666"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                />
                <h1 className="text-3xl text-ptxt mt-5">Your score is being calculated</h1>
                <p className="text-lg text-stxt">
                    Rest assured that hard work will pay off. I wish you all the best in your upcoming exams.
                </p>
            </div>
        </div>
    </div>
}
